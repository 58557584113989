import React from "react";
export default function SwitchIcon(props) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1649_177)">
        <path
          d="M45.2134 24.6268L41.04 11.2135C40.872 10.6692 40.5341 10.1931 40.0758 9.85476C39.6175 9.51645 39.063 9.33378 38.4934 9.3335H9.50669C8.93707 9.33378 8.38251 9.51645 7.92423 9.85476C7.46596 10.1931 7.12806 10.6692 6.96002 11.2135L2.78669 24.6402C2.70757 24.8949 2.66712 25.1601 2.66669 25.4268V36.0002C2.66669 36.7074 2.94764 37.3857 3.44774 37.8858C3.94783 38.3859 4.62611 38.6668 5.33335 38.6668H42.6667C43.3739 38.6668 44.0522 38.3859 44.5523 37.8858C45.0524 37.3857 45.3334 36.7074 45.3334 36.0002V25.4135C45.3329 25.1467 45.2925 24.8816 45.2134 24.6268ZM11.8934 33.3335H9.49335V29.3335H11.8934V33.3335ZM18.56 33.3335H16.16V29.3335H18.56V33.3335ZM25.2267 33.3335H22.8267V29.3335H25.2267V33.3335ZM31.8934 33.3335H29.4667V29.3335H31.8667L31.8934 33.3335ZM38.56 33.3335H36.1334V29.3335H38.5334L38.56 33.3335ZM41.3334 25.8668H6.66669V24.0002H41.3334V25.8668Z"
          fill="#006798"
        />
      </g>
      <defs>
        <clipPath id="clip0_1649_177">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
