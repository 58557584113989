import * as React from "react";

//React router dom
import { Route, Routes } from "react-router-dom";

//Context
import { TimeContext, timeConfigs } from "../../config/time";

import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

//Selfmade components
import BottomNavbar from "../../components/navigation/bottomNavbar";
import Navbar from "../../components/navigation/navbar";
import ReportViewer from "./report";
import Dashboard from "./dashboard";
import EmbedViewer from "./embed";
import SelectPeriod from "../../components/input/selectPeriod";

export default function Home(props) {
  const [timeConfig, setTimeConfig] = React.useState(timeConfigs.currentYear);

  const { isAuthenticated } = props;

  const time = isAuthenticated ? localStorage.getItem("timeConfig") : "cyear";

  //const [page, setPage] = React.useState(location.pathname);

  //Time effect
  React.useEffect(() => {
    switch (time) {
      case "cyear":
        setTimeConfig(timeConfigs.currentYear);
        break;

      case "lyear":
        setTimeConfig(timeConfigs.lastYear);
        break;

      default:
        setTimeConfig(timeConfigs.currentYear);
        break;
    }
  }, [time]);

  return (
    <TimeContext.Provider value={{ timeConfig, setTimeConfig }}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Navbar isAuthenticated={isAuthenticated} />
        <Box
          component="main"
          sx={{ flexGrow: 1, height: "100vh", bgcolor: "#C1CCD2" }}
        >
          <Toolbar />
          {isAuthenticated ? (
            <React.Fragment>
              <BottomNavbar isAuthenticated={isAuthenticated} />

              <Grid
                container
                sx={{ display: { xs: "inherit", sm: "none" }, padding: "16px" }}
              >
                <SelectPeriod />
              </Grid>
            </React.Fragment>
          ) : null}
          <Routes>
            <Route
              path="/"
              element={
                <Dashboard isAuthenticated={isAuthenticated} page={"traffic"} />
              }
            />
            <Route
              path="/maintenance"
              element={
                <Dashboard
                  isAuthenticated={isAuthenticated}
                  page={"maintenance"}
                />
              }
            />
            <Route path="/Analysis/:reportId" element={<ReportViewer />} />
            <Route path="/Curbside" element={<EmbedViewer />} />
          </Routes>
        </Box>
      </Box>
    </TimeContext.Provider>
  );
}
