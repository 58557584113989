import React, { useState } from "react";

import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";

//Selfmade
import ProfilePopover from "../popovers/profile";
import { useNavigate, useLocation } from "react-router-dom";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import { ThemeProvider } from "@emotion/react";
import darkTheme from "../../config/secondaryTheme";
import CameraIcon from "../icons/CameraIcon";
import TwoCarsIcon from "../icons/TwoCarsIconsForNarvar";

export default function Navbar(props) {
  const { isAuthenticated } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const navbarName = isAuthenticated
    ? JSON.parse(localStorage.getItem("userInfo")).fullName
    : "Sign in";

  //Profile popover management
  const [anchorProfile, setAnchorProfile] = useState(null);
  const handleProfilePopoverOpen = (event) => {
    if (isAuthenticated) setAnchorProfile(event.currentTarget);
    else navigate("/login");
  };

  const handleProfilePopoverClose = () => {
    setAnchorProfile(null);
  };

  const handlePageChange = (event, newPage) => {
    navigate(newPage);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <ProfilePopover
        open={anchorProfile}
        handleClose={handleProfilePopoverClose}
      />
      <Toolbar>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={3} md={6} container alignItems={"center"}>
            <img
              src="/assets/images/Logo.png"
              alt="Bellevue white logo"
              height={"45px"}
            />
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                pl: 2,
                display: { xs: "none", md: "block" },
              }}
            >
              {"Bellevue Transportation Department"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            md={5}
            container
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <ThemeProvider theme={darkTheme}>
              <ToggleButtonGroup
                value={location.pathname}
                exclusive
                onChange={handlePageChange}
              >
                <ToggleButton
                  value={
                    location.pathname !== "/maintenance"
                      ? location.pathname
                      : "/"
                  }
                >
                  <TwoCarsIcon
                    selected={location.pathname !== "/maintenance"}
                  />
                  {/* <Typography
                    sx={{
                      fontWeight: location.pathname === "/" ? 600 : 400,
                      marginRight: location.pathname === "/" ? "15px" : "30px",
                    }}
                  > */}
                  <span
                    style={{
                      marginRight:
                        location.pathname !== "/maintenance" ? "0px" : "30px",
                    }}
                  >
                    Traffic Operations
                  </span>
                  {/*  </Typography> */}
                </ToggleButton>
                <ToggleButton value="/maintenance">
                  <CameraIcon selected={location.pathname === "/maintenance"} />
                  {/* <Typography
                    sx={{
                      fontWeight:
                        location.pathname === "/maintenance" ? 600 : 400,
                    }}
                  > */}
                  Device Maintenance
                  {/* </Typography> */}
                </ToggleButton>
              </ToggleButtonGroup>
            </ThemeProvider>
          </Grid>
          <Grid item container xs={3} md={1} justifyContent={"flex-end"}>
            <ButtonBase
              color="inherit"
              sx={{ fontWeight: 600 }}
              onClick={handleProfilePopoverOpen}
            >
              {navbarName}
            </ButtonBase>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
