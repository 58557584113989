import React from "react";

//Context

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

export default function TrafficIncidentsHeader(props) {
  const { description } = props;
  //const { headerSizes } = props;
  const { title } = props;
  const { Icon } = props;

  return (
    <Grid
      item
      sm={12}
      container
      alignItems="center"
      sx={{ margin: 0, flexWrap: "nowrap" }}
    >
      <Icon sx={{ fontSize: 48 }} color="primary" />
      <Typography
        color="primary"
        sx={{ fontWeight: 700, fontSize: 30, ml: 1, mr: 2, mb: 0 }}
      >
        {title}
      </Typography>
      <p className="header-description">{description}</p>
    </Grid>
  );
}
