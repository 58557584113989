import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#006798",
      light: "#008ACC",
    },
    secondary: {
      main: "#888C8C",
    },
    success: {
      main: "#28A44D",
    },
    error: {
      main: "#C33149",
    },
    warning: {
      main: "#ED7D31",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1900,
    },
  },
});

export default theme;
