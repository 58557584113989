import { useTheme } from "@mui/material/styles"; // or @mui/joy/styles
import useMediaQuery from "@mui/material/useMediaQuery";

export const useWidth = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys];
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));

      return matches ? key : output;
    }, null) ?? "xs"
  );
};
