import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#006798",
      light: "#008ACC",
    },
    secondary: {
      main: "#888C8C",
    },
    success: {
      main: "#28A44D",
    },
    error: {
      main: "#C33149",
    },
    warning: {
      main: "#ED7D31",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1900,
    },
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          height: "36px",
          padding: "0 20px",
          borderRadius: "26px",
          backgroundColor: "rgba(255, 255, 255, 0.25)",
          fontWeight: 400,
          fontSize: "14px",
          "&:hover": {
            filter: "drop-shadow(0 1px 1px rgba(0, 0, 0, 0.4))",
          },
          "&.Mui-selected": {
            backgroundColor: "#FFFFFF",
            color: "#006798",
            fontWeight: 600,
            "&:hover": {
              backgroundColor: "#FFFFFF",
            },
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          "&:not(:first-of-type)": {
            borderRadius: "26px",
            marginLeft: "-30px",
          },
          "&:first-of-type": {
            borderRadius: "26px",
          },
        },
      },
    },
  },
});

export default darkTheme;
