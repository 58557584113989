import { createContext } from "react";

const today = new Date(Date.now());
const thisMonth = today.getMonth();
const thisYear = today.getFullYear();
const lastMonthDate = new Date(new Date().setMonth(thisMonth - 1));
const lastYearDate = new Date(new Date().setFullYear(thisYear - 1));
const firstDayOfYear = new Date(thisYear, 1, 1);
const firstDayOfMonthLastYear = new Date(lastYearDate.setDate(1));
const lastDayOfLastMonthThisYear = new Date(thisYear, thisMonth, 0);
const firstDayOfMonth = new Date(thisYear, thisMonth, 1);
const firstDayOfLastMonth = new Date(thisYear, thisMonth - 1, 1);

const monthName = new Intl.DateTimeFormat("en-US", { month: "long" }).format;
const customDate = (date) =>
  `${monthName(date)} ${date.getDate()}, ${date.getFullYear()}`;

const customMonth = (date, variation) =>
  `${monthName(date)} ${
    isNaN(variation) ? date.getFullYear() : date.getFullYear() + variation
  }`;

const janToNow = `Jan 1 - ${customDate(today)}`;
const janToPrevYear = `Jan 1 - ${customDate(lastYearDate)} `;

const last12Months = `${customMonth(today, -1)} - ${customMonth(
  lastMonthDate,
)} `;

const last24Months = `${customMonth(today, -2)} - ${customMonth(
  lastMonthDate,
  -1,
)} `;

const monthToDate = `${monthName(today)} 1 - ${customDate(today)}`;
const lastMonthToDate = `${monthName(lastYearDate)} 1 - ${customDate(
  lastYearDate,
)}`;

export const timeConfigs = {
  currentYear: {
    value: "cyear",
    variationDescription: `${janToNow} compared to ${janToPrevYear}`,
    incidentsDescription: `Incidents reported ${janToNow}`,
    bicyclistDescription: `Cyclists counted ${janToNow}`,
    corridorsDescription: `Corridors that meet urban travel speed standards ${janToNow}`,
    noData: "No data for last year",
    dateDifference: `${firstDayOfYear.toLocaleDateString()} - ${today.toLocaleDateString()}`,
  },
  lastYear: {
    value: "lyear",
    variationDescription: `${last12Months} compared to ${last24Months}`,
    incidentsDescription: `Incidents reported ${last12Months}`,
    bicyclistDescription: `Cyclists counted ${last12Months}`,
    corridorsDescription: `Corridors that meet urban travel speed standards ${last12Months}`,
    noData: "No data for last year",
    dateDifference: `${firstDayOfMonthLastYear.toLocaleDateString()} - ${lastDayOfLastMonthThisYear.toLocaleDateString()}`,
  },
  currentMonth: {
    value: "cmonth",
    variationDescription: `${monthToDate} compared to ${lastMonthToDate}`,
    incidentsDescription: `Incidents reported ${monthToDate}`,
    bicyclistDescription: `Cyclists counted ${monthToDate}`,
    corridorsDescription: `Corridors that meet urban travel speed standards in ${monthToDate}`,
    noData: "No data for last month",
    dateDifference: `${firstDayOfMonth.toLocaleDateString()} - ${today.toLocaleDateString()}`,
  },
  lastMonth: {
    value: "lmonth",
    variationDescription: `${customMonth(
      lastMonthDate,
    )} compared to ${customMonth(lastMonthDate, -1)}`,
    incidentsDescription: `Incidents reported ${customMonth(lastMonthDate)}`,
    bicyclistDescription: `Cyclists counted ${customMonth(lastMonthDate)}`,
    corridorsDescription: `Corridors that meet urban travel speed standards ${customMonth(
      lastMonthDate,
    )}`,
    noData: "No data for last month",
    dateDifference: `${firstDayOfLastMonth.toLocaleDateString()} - ${lastDayOfLastMonthThisYear.toLocaleDateString()}`,
  },
};

export const TimeContext = createContext({
  timeConfig: timeConfigs.lastYear,
  changeTimeConfig: () => {},
});
