import React, { useState, useEffect } from "react";

//Context

//import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

//Jquery
import apiURL from "../../config/environment";
import $ from "jquery";

export default function CustomTable(props) {
  const [tableInfo, setTableInfo] = useState([]);

  const { includeAverage, tableTitleAddition } = props;
  const { endpoint } = props;
  const { subTitle } = props;
  const { title } = props;

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    let isSubscribed = true;
    const token = JSON.parse(localStorage.getItem("userInfo"))?.token;

    $.ajax({
      method: "GET",
      url: apiURL + endpoint,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    }).done((res) => {
      if (isSubscribed) {
        setTableInfo(res);
      }
    });

    return () => (isSubscribed = false);
  }, [endpoint]);

  return (
    <React.Fragment>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems={"flex-end"}
        sm={12}
        sx={{ paddingRight: "1rem" }}
      >
        <Grid item sm={6}>
          <Typography
            variant={"h5"}
            className="condensed"
            color="primary"
            sx={{ fontWeight: 500 }}
          >
            {`${title} ${tableTitleAddition}`}
          </Typography>
        </Grid>
        <Grid item sm={3} container justifyContent="flex-end">
          <Typography
            variant="body2"
            color="primary"
            className="condensed"
            sx={{ textAlign: "end", fontWeight: 500 }}
          >
            {subTitle}
          </Typography>
        </Grid>
        {includeAverage ? (
          <Grid item sm={3} container justifyContent="flex-end">
            <Typography variant="body2" color="primary" className="condensed">
              {"Daily Average"}
            </Typography>
          </Grid>
        ) : null}
      </Grid>

      <Grid
        item
        sm={12}
        sx={{ paddingTop: "2px !important", paddingBottom: "0.5rem" }}
      >
        <Divider variant="fullWidth" />
      </Grid>

      <Grid
        item
        container
        rowSpacing={2}
        sm={12}
        sx={{
          maxHeight: "40%",
          overflowY: "scroll",
          paddingRight: "1rem",
          marginTop: "0.5rem",
          paddingBottom: "1rem",
        }}
      >
        {tableInfo.map((value, key) => (
          <Grid
            key={key}
            item
            sm={12}
            container
            justifyContent={"space-between"}
            alignItems="center"
            sx={{ paddingTop: key === 0 ? "0 !important" : "16px" }}
          >
            <Grid item xs={9}>
              <Typography
                variant="body2"
                color="textSecondary"
                className="condensed"
              >
                {value.location}
              </Typography>
            </Grid>
            <Grid item xs={3} container justifyContent="flex-end">
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ fontWeight: "bold" }}
              >
                {numberWithCommas(value.value)}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}
