import React, { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";

import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import { useTheme } from "@mui/material/styles";

export default function PercentageValue(props) {
  const numberWithCommas = (number) => {
    if (number === null) return "-";
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const [arrow, setArrow] = useState(null);
  const [color, setColor] = useState("primary");

  const theme = useTheme();

  const {
    periodValuePercentage,
    periodDescription1,
    periodDescription2,
    periodVariation,
    tooltipSubTitle,
    periodInterval,
    showMoreDigits,
    usePercentage,
    alterTooltip,
    invertArrows,
    invertColors,
    periodValue,
    breakpoint,
  } = props;

  const formatVariation = (value, variation) => {
    const decimals = showMoreDigits ? 2 : 0;
    if (value === null || variation === null) return "-";
    else return `${Math.abs(periodVariation.toFixed(decimals))}%`;
  };

  useEffect(() => {
    const success = theme.palette.success.main;
    const error = theme.palette.error.main;
    if (
      Math.abs(periodVariation) === 100 ||
      periodValue === 0 ||
      periodVariation === 0
    ) {
      setColor("primary");
      setArrow(null);
    } else if (periodVariation > 0) {
      setColor(invertColors ? error : success);
      setArrow(
        invertArrows ? (
          <ArrowDownwardIcon sx={{ color: invertColors ? error : success }} />
        ) : (
          <ArrowUpwardIcon sx={{ color: invertColors ? error : success }} />
        ),
      );
    } else if (periodVariation < 0) {
      setColor(invertColors ? success : error);
      setArrow(
        invertArrows ? (
          <ArrowUpwardIcon sx={{ color: invertColors ? success : error }} />
        ) : (
          <ArrowDownwardIcon sx={{ color: invertColors ? success : error }} />
        ),
      );
    } else {
      setColor("primary");
      setArrow(null);
    }
  }, [invertArrows, invertColors, theme, periodValue, periodVariation]);

  return (
    <React.Fragment>
      <Tooltip
        title={
          alterTooltip ? (
            <React.Fragment>
              {periodValue === null ? (
                <Typography variant="body2" textAlign={"center"}>
                  {`No data`}
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  textAlign={"center"}
                >{`${periodValuePercentage}% (${numberWithCommas(
                  periodValue,
                )} ${tooltipSubTitle})`}</Typography>
              )}
              <Typography variant="body2" textAlign={"center"}>
                {periodInterval}
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {periodValue === null ? (
                <Typography variant="body2" textAlign={"center"}>
                  {`No data`}
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  textAlign={"center"}
                >{`${numberWithCommas(
                  periodValue,
                )} ${tooltipSubTitle}`}</Typography>
              )}

              <Typography variant="body2" textAlign={"center"}>
                {periodInterval}
              </Typography>
            </React.Fragment>
          )
        }
      >
        <Grid
          item
          sm={12}
          container
          alignItems="center"
          justifyContent="center"
          sx={{ flexWrap: "nowrap" }}
        >
          {arrow}
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 29,
              letterSpacing: showMoreDigits
                ? "-2px"
                : usePercentage
                  ? "-1px"
                  : "-0.5px",
              fontFamily:
                showMoreDigits && breakpoint === "xl"
                  ? "Roboto Condensed"
                  : undefined,
            }}
            color={color}
          >
            {formatVariation(periodValue, periodVariation)}
          </Typography>
        </Grid>
      </Tooltip>
      <Typography
        color="textSecondary"
        fontSize={12}
        textAlign="center"
        className="condensed no-wrap"
      >
        {periodDescription1}
      </Typography>
      <Typography
        color="textSecondary"
        fontSize={12}
        textAlign="center"
        className="condensed no-wrap"
      >
        {periodDescription2}
      </Typography>
    </React.Fragment>
  );
}
