import React from "react";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";

const numberWithCommas = (number) => {
  if (number === null) return "-";
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const numberAbreviation = (number) => {
  if (number === null) return "-";
  if (number > 1000000) {
    return `${(number / 1000000).toFixed(2)}M`;
  } else if (number > 1000) {
    return `${(number / 1000).toFixed(0)}K`;
  } else {
    return number.toString();
  }
};

const makeTooltipPopupTitle = (
  alterTooltip,
  periodInterval,
  periodValuePercentage,
  tooltipSubTitle,
  periodValue,
) => (
  <React.Fragment>
    <Typography variant="body2" textAlign="center">
      {periodValue === null
        ? `No data`
        : alterTooltip
          ? `${periodValuePercentage}% (${numberWithCommas(periodValue)} ${tooltipSubTitle})`
          : `${numberWithCommas(periodValue)} ${tooltipSubTitle}`}
    </Typography>
    <Typography variant="body2" textAlign="center">
      {periodInterval}
    </Typography>
  </React.Fragment>
);

const makeSx = (primary, showMoreDigits, breakpoint, usePercentage) => {
  return {
    fontWeight: primary ? 800 : 500,
    fontSize: 39,
    fontFamily:
      showMoreDigits && breakpoint === "xl" ? "Roboto Condensed" : undefined,
    letterSpacing: showMoreDigits ? "-2px" : usePercentage ? "-1px" : "-0.5px",
  };
};

const makeValue = (
  usePercentage,
  periodValuePercentage,
  showActualNumber,
  periodValue,
) =>
  usePercentage
    ? periodValuePercentage === null
      ? "-"
      : `${periodValuePercentage}%`
    : periodValue === null
      ? "-"
      : showActualNumber
        ? numberWithCommas(periodValue)
        : numberAbreviation(periodValue.toFixed(0));

const MainValue = ({
  periodValuePercentage,
  showActualNumber,
  periodDescription,
  tooltipSubTitle,
  periodInterval,
  showMoreDigits,
  usePercentage,
  alterTooltip,
  includeUnits,
  periodValue,
  breakpoint,
  type,
  primary,
}) => {
  const mainValue = makeValue(
    usePercentage,
    periodValuePercentage,
    showActualNumber,
    periodValue,
  );

  const tooltipPopUpTitle = makeTooltipPopupTitle(
    alterTooltip,
    periodInterval,
    periodValuePercentage,
    tooltipSubTitle,
    periodValue,
  );

  const sx = makeSx(primary, showMoreDigits, breakpoint, usePercentage);

  return (
    <React.Fragment>
      <Tooltip title={tooltipPopUpTitle}>
        <Grid container alignItems="baseline" justifyContent="center">
          <Typography sx={sx} color="primary">
            {mainValue}
          </Typography>
          {includeUnits && mainValue !== "-" && (
            <Typography color="primary" className="condensed" variant="h5">
              {type}
            </Typography>
          )}
        </Grid>
      </Tooltip>
      <Typography
        color="textSecondary"
        fontSize={12}
        textAlign="center"
        className="condensed no-wrap"
      >
        {periodDescription}
      </Typography>
    </React.Fragment>
  );
};

export default MainValue;
