import React from "react";
export default function CarDetectorIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.22 28.22C19.22 24.06 21.18 20.38 24.2 18H10L13 9H35L37.44 16.32C39.12 17.06 40.6 18.14 41.82 19.48L37.84 8C37.44 6.84 36.32 6 35 6H13C11.68 6 10.56 6.84 10.16 8L6 20V36C6 37.1 6.9 38 8 38H10C11.1 38 12 37.1 12 36V34H20.58C19.72 32.26 19.22 30.3 19.22 28.22ZM13 28C11.34 28 10 26.66 10 25C10 23.34 11.34 22 13 22C14.66 22 16 23.34 16 25C16 26.66 14.66 28 13 28ZM41.42 37.4L41.4 37.42L41.42 37.4ZM32.22 19.22C37.22 19.22 41.22 23.22 41.22 28.22C41.22 30 40.72 31.64 39.84 33L46 39.22L43.22 42L37 35.86C35.6 36.72 34 37.22 32.22 37.22C27.22 37.22 23.22 33.22 23.22 28.22C23.22 23.22 27.22 19.22 32.22 19.22ZM32.22 23.22C31.2311 23.22 30.2644 23.5132 29.4421 24.0627C28.6199 24.6121 27.979 25.393 27.6006 26.3066C27.2222 27.2202 27.1231 28.2255 27.3161 29.1954C27.509 30.1654 27.9852 31.0563 28.6845 31.7555C29.3837 32.4548 30.2746 32.931 31.2445 33.1239C32.2145 33.3169 33.2198 33.2178 34.1334 32.8394C35.047 32.461 35.8279 31.8201 36.3773 30.9979C36.9268 30.1756 37.22 29.2089 37.22 28.22C37.22 25.44 35 23.22 32.22 23.22Z"
        fill="#006798"
      />
    </svg>
  );
}
