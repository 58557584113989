import React, { useState, useEffect, useContext, useRef } from "react";

//Context
import { TimeContext } from "../../config/time";

import Grid from "@mui/material/Grid";

import ReactEcharts from "echarts-for-react";

//Jquery
import apiURL from "../../config/environment";
import { CircularProgress, Typography } from "@mui/material";

const line_series = (cat_name, item_color, symbol, symbolSize, dat) => {
  return {
    name: cat_name,
    type: "line",
    smooth: false,
    symbol,
    symbolSize,
    yAxisIndex: 1,
    itemStyle: {
      borderWidth: 2,
      color: item_color,
    },
    data: dat,
  };
};

const bar_series = (cat_name, item_color, year, dat) => {
  return {
    name: cat_name,
    type: "bar",
    stack: `${year}`,
    smooth: false,
    yAxisIndex: 0,
    itemStyle: {
      borderWidth: 0,
      color: item_color,
    },
    data: dat,
  };
};

const make_chart_settings = (prev_year, curr_year, months, series) => {
  return {
    // Global text styles
    textStyle: {
      fontFamily: "Roboto, Arial, Verdana, sans-serif",
      fontSize: 13,
    },

    // Cha23rt animation duration
    animationDuration: 750,

    title: {
      text: "Intersections Congested for",
      left: "center",

      textStyle: {
        fontSize: 14,
        fontWeight: 400,
        color: "black",
      },
    },

    // Setup grid
    grid: {
      left: 30,
      right: 30,
      top: 80,
      bottom: 10,
      containLabel: true,
    },

    legend: {
      data: [
        "15-29 Min",
        "30-59 Min",
        "60+ Min",
        `Total Minutes ${prev_year}`,
        `Total Minutes ${curr_year}`,
      ],
      left: "center",
      width: 400,
      height: 200,
      itemHeight: 5,
      itemGap: 10,
      top: 30,
    },

    // Add tooltip
    tooltip: {
      trigger: "axis",
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: "Roboto, sans-serif",
      },
    },

    // Horizontal axis
    xAxis: [
      {
        type: "category",
        boundaryGap: true,
        axisLabel: {
          color: "#333",
        },
        axisLine: {
          lineStyle: {
            color: "#999",
          },
        }, //Fechas
        data: months,
      },
    ],

    // Vertical axis
    yAxis: [
      {
        type: "value",
        name: "Number of Intersections",
        nameLocation: "middle",
        nameGap: 35,
        // interval: 50,
        // max: 250,
        //min: 30,
      },
      {
        type: "value",
        name: "Avg Total Mins Congested",
        nameLocation: "middle",
        nameGap: 35,
        // max: 70,
        // min: 0,
      },
    ],

    series,
  };
};

const C_MONTH_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const group_by = (xs, key) =>
  xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

const pad_year_data_with_nulls = (year, res) => {
  const max_month = Math.max(...res.map((x) => x.month));
  const data_map = new Map(res.map((x) => [x.month, x]));
  const result = [];
  for (let month = 1; month <= max_month; month++) {
    if (data_map.has(month)) {
      result.push(data_map.get(month));
    } else {
      result.push({
        year: parseInt(year),
        month,
        avg_congested: null,
        cs_15_30: null,
        cs_30_60: null,
        cs_60_plus: null,
      });
    }
  }
  return result;
};

const pad_data_with_nulls = (res) => {
  let result = [];
  const grouped_data = group_by(res, "year");
  for (const [year, dat] of Object.entries(grouped_data)) {
    const padded_year = pad_year_data_with_nulls(year, dat);
    result = result.concat(padded_year);
  }
  return result;
};

const make_series = (res) => {
  const years = res.map((x) => x.year);
  const month_nums = res.map((x) => x.month);
  const avg_congested = res.map((x) => x.avg_congested);
  const cs15_30 = res.map((x) => x.cs_15_30);
  const cs30_60 = res.map((x) => x.cs_30_60);
  const cs60_plus = res.map((x) => x.cs_60_plus);

  const pivotted_data = {
    years,
    month_nums,
    avg_congested,
    cs15_30,
    cs30_60,
    cs60_plus,
  };

  const unique_years = [...new Set(pivotted_data.years)].sort();

  // handle edge case where we don't have 2 distinct years to compare
  if (unique_years.length !== 2) {
  }

  const [prev_year, curr_year] = unique_years;

  const year_indicies = unique_years.map((year) =>
    pivotted_data.years.findIndex((x) => x === year),
  );

  // these are used for slicing, meaning start is inclusive and end is
  // exclusive
  const i_prev_start = 0;
  const i_prev_end = year_indicies.at(1);
  const i_curr_start = i_prev_end;
  const i_curr_end = pivotted_data.years.length;

  const prev_data = {
    years: pivotted_data.years.slice(i_prev_start, i_prev_end),
    month_nums: pivotted_data.month_nums.slice(i_prev_start, i_prev_end),
    avg_congested: pivotted_data.avg_congested.slice(i_prev_start, i_prev_end),
    cs15_30: pivotted_data.cs15_30.slice(i_prev_start, i_prev_end),
    cs30_60: pivotted_data.cs30_60.slice(i_prev_start, i_prev_end),
    cs60_plus: pivotted_data.cs60_plus.slice(i_prev_start, i_prev_end),
  };

  const curr_data = {
    years: pivotted_data.years.slice(i_curr_start, i_curr_end),
    month_nums: pivotted_data.month_nums.slice(i_curr_start, i_curr_end),
    avg_congested: pivotted_data.avg_congested.slice(i_curr_start, i_curr_end),
    cs15_30: pivotted_data.cs15_30.slice(i_curr_start, i_curr_end),
    cs30_60: pivotted_data.cs30_60.slice(i_curr_start, i_curr_end),
    cs60_plus: pivotted_data.cs60_plus.slice(i_curr_start, i_curr_end),
  };

  const series_values = [
    bar_series("15-29 Min", "#F8CBAD", curr_year, curr_data.cs15_30),
    bar_series("30-59 Min", "#F4B183", curr_year, curr_data.cs30_60),
    bar_series("60+ Min", "#F1975A", curr_year, curr_data.cs60_plus),
    bar_series("15-29 Min", "#99D0EB", prev_year, prev_data.cs15_30),
    bar_series("30-59 Min", "#66B9E1", prev_year, prev_data.cs30_60),
    bar_series("60+ Min", "#008ACC", prev_year, prev_data.cs60_plus),
    line_series(
      `Total Minutes ${curr_year}`,
      "#e48142",
      "circle",
      7,
      curr_data.avg_congested,
    ),
    line_series(
      `Total Minutes ${prev_year}`,
      "#249ad3",
      "emptyCircle",
      6,
      prev_data.avg_congested,
    ),
  ].flat();

  return [prev_year, curr_year, series_values];
};

export default function PMCongestionChart({ endpoint }) {
  const timeConfig = useContext(TimeContext).timeConfig;
  const [res_body, setRes] = useState(null);
  const [error, setError] = useState(null);

  const container = useRef(null);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo"))?.token;

    fetch(apiURL + endpoint + timeConfig.value, {
      headers: {
        Authorization: "Bearer " + token,
        "Context-Type": "application/json",
      },
    })
      .catch((reason) => setError(reason))
      .then((response) => response.json())
      .then((body) => setRes(body));

    return () => {};
  }, [timeConfig, endpoint]);

  if (error !== null) {
    return (
      <Grid item xs={12} ref={container}>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          height={"324px"}
        >
          <Typography color="error">{"Error fetching data"}</Typography>
        </Grid>
      </Grid>
    );
  }

  if (res_body === null) {
    return (
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        height={"324px"}
      >
        <CircularProgress />
      </Grid>
    );
  }

  const padded_data = pad_data_with_nulls(res_body);

  console.log(padded_data);

  const [prev_year, curr_year, series_values] = make_series(padded_data);

  const chart_settings = make_chart_settings(
    prev_year,
    curr_year,
    C_MONTH_NAMES,
    series_values,
  );

  return (
    <Grid item xs={12} ref={container}>
      <ReactEcharts style={{ height: "324px" }} option={chart_settings} />
    </Grid>
  );
}
