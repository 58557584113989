import React, { useState, useEffect } from "react";

import Divider from "@mui/material/Divider";

import Grid from "@mui/material/Grid";

import { useTheme } from "@mui/material/styles";
import { useWidth } from "../../config/widthSelector";

import PercentageValue from "./percentageValue";

//Jquery
import apiURL from "../../config/environment";
import $ from "jquery";
import MainValue from "./mainValue";

export default function KPIValues(props) {
  const {
    showActualNumber,
    tooltipSubTitle,
    showMoreDigits,
    usePercentage,
    defaultValues,
    alterTooltip,
    includeUnits,
    invertArrows,
    invertColors,
    endpoint,
    type,
  } = props;

  const theme = useTheme();
  const breakpoint = useWidth();

  const [info, setInfo] = useState(defaultValues);

  const calculatePercentage = (minuend, sustraend, decimals) => {
    const difference = minuend - sustraend;
    if (difference > 100) return 100;
    else return difference.toFixed(decimals);
  };

  /* 
  Note: We have 5 different variables, each one of them has 4 different variations:

  periodValue = The main value for the specified period
  periodInterval= The string that specified the starting and ending dates for the specified period
  periodValuePercentage = The percentage variation for the specified period
  periodVariation = The actual variation of the specified period

  The periods are: actual, lastYear, lastMonth, previousMonth and thisMonthLastYear
  
  */

  useEffect(() => {
    let isSubscribed = true;
    const token = JSON.parse(localStorage.getItem("userInfo"))?.token;
    $.ajax({
      method: "GET",
      url: apiURL + endpoint,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    }).done((res) => {
      if (isSubscribed) {
        const decimals = showMoreDigits ? 2 : 0;
        setInfo({
          ...defaultValues,
          ...res,
          previousMonthPeriodPercentage: calculatePercentage(
            res.lastMonthPeriodValuePercentage,
            res.previousMonthPeriodVariation,
            decimals,
          ),
          thisMonthLastYearPeriodPercentage: calculatePercentage(
            res.lastMonthPeriodValuePercentage,
            res.thisMonthLastYearPeriodVariation,
            decimals,
          ),
          lastYearPeriodPercentage: calculatePercentage(
            res.actualPeriodValuePercentage,
            res.lastYearPeriodVariation,
            decimals,
          ),
        });
      }
    });

    return () => (isSubscribed = false);
  }, [endpoint, defaultValues, showMoreDigits]);

  return (
    <Grid item sm={12} container spacing={1}>
      <Grid item xs={12} sm={5} container spacing={1} alignItems={"flex-end"}>
        <Grid
          item
          xs={showMoreDigits ? 5 : 6}
          container
          direction="column"
          alignItems="center"
        >
          <MainValue
            periodValuePercentage={info.actualPeriodValuePercentage}
            periodDescription={info.actualPeriodDescription}
            periodInterval={info.actualPeriodInterval}
            periodValue={info.actualPeriodValue}
            showActualNumber={showActualNumber}
            tooltipSubTitle={tooltipSubTitle}
            showMoreDigits={showMoreDigits}
            usePercentage={usePercentage}
            alterTooltip={alterTooltip}
            includeUnits={includeUnits}
            breakpoint={breakpoint}
            type={type}
            primary
          />
        </Grid>
        <Grid
          item
          xs={showMoreDigits ? 6 : 5}
          container
          direction="column"
          alignItems="center"
        >
          <PercentageValue
            periodValuePercentage={info.lastYearPeriodPercentage}
            periodDescription1={info.lastYearPeriodDescription1}
            periodDescription2={info.lastYearPeriodDescription2}
            periodVariation={info.lastYearPeriodVariation}
            periodInterval={info.lastYearPeriodInterval}
            periodValue={info.lastYearPeriodValue}
            tooltipSubTitle={tooltipSubTitle}
            showMoreDigits={showMoreDigits}
            usePercentage={usePercentage}
            alterTooltip={alterTooltip}
            invertArrows={invertArrows}
            invertColors={invertColors}
            breakpoint={breakpoint}
          />
        </Grid>

        <Grid
          item
          xs={1}
          container
          justifyContent="flex-end"
          sx={{ height: "100%" }}
        >
          <Divider
            orientation="vertical"
            sx={{
              borderRightColor: theme.palette.primary.light,
              borderRightStyle: "dotted",
              borderRightWidth: "1px",
              borderLeft: "none",
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={7} container spacing={1} alignItems={"flex-end"}>
        <Grid item xs={4} container direction="column" alignItems="center">
          <MainValue
            periodValuePercentage={info.lastMonthPeriodValuePercentage}
            periodDescription={info.lastMonthPeriodDescription}
            periodInterval={info.lastMonthPeriodInterval}
            periodValue={info.lastMonthPeriodValue}
            showActualNumber={showActualNumber}
            tooltipSubTitle={tooltipSubTitle}
            showMoreDigits={showMoreDigits}
            usePercentage={usePercentage}
            alterTooltip={alterTooltip}
            breakpoint={breakpoint}
            type={type}
          />
        </Grid>

        <Grid item xs={4} container direction="column" alignItems="center">
          <PercentageValue
            periodValuePercentage={info.previousMonthPeriodPercentage}
            periodDescription1={info.previousMonthPeriodDescription1}
            periodDescription2={info.previousMonthPeriodDescription2}
            periodVariation={info.previousMonthPeriodVariation}
            periodInterval={info.previousMonthPeriodInterval}
            periodValue={info.previousMonthPeriodValue}
            tooltipSubTitle={tooltipSubTitle}
            showMoreDigits={showMoreDigits}
            usePercentage={usePercentage}
            alterTooltip={alterTooltip}
            invertArrows={invertArrows}
            invertColors={invertColors}
            breakpoint={breakpoint}
          />
        </Grid>

        <Grid item xs={4} container direction="column" alignItems="center">
          <PercentageValue
            periodValuePercentage={info.thisMonthLastYearPeriodPercentage}
            periodDescription1={info.thisMonthLastYearPeriodDescription1}
            periodDescription2={info.thisMonthLastYearPeriodDescription2}
            periodVariation={info.thisMonthLastYearPeriodVariation}
            periodInterval={info.thisMonthLastYearPeriodInterval}
            periodValue={info.thisMonthLastYearPeriodValue}
            tooltipSubTitle={tooltipSubTitle}
            showMoreDigits={showMoreDigits}
            usePercentage={usePercentage}
            alterTooltip={alterTooltip}
            invertArrows={invertArrows}
            invertColors={invertColors}
            breakpoint={breakpoint}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
