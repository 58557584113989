import React from "react";
export default function CameraIcon(props) {
  const { selected } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: "8px", marginLeft: !selected ? "30px" : "" }}
    >
      <path
        d="M15.5151 6.21832L5.33591 1.73749C5.13224 1.64819 4.90145 1.6434 4.69425 1.72415C4.59185 1.76472 4.49849 1.82514 4.41954 1.90194C4.34059 1.97875 4.27762 2.07041 4.23425 2.17165L1.73425 8.00499C1.64719 8.2081 1.64438 8.43748 1.72642 8.64267C1.80847 8.84786 1.96865 9.01206 2.17175 9.09915L7.96925 11.5833L6.93591 14.1667H3.33341V11.6667H1.66675V18.3333H3.33341V15.8333H6.93591C7.62175 15.8333 8.22925 15.4217 8.48258 14.785L9.50092 12.24L12.3501 13.4608C12.5523 13.5477 12.7806 13.5511 12.9853 13.4702C13.19 13.3893 13.3544 13.2308 13.4426 13.0292L15.9426 7.31499C16.031 7.11284 16.0357 6.88386 15.9556 6.67827C15.8754 6.47268 15.717 6.30727 15.5151 6.21832ZM16.6084 13.6433L15.0601 13.025L16.7251 8.85832L18.2734 9.47582L16.6084 13.6433Z"
        fill={selected ? "#006798" : "#FFFFFF"}
      />
    </svg>
  );
}
