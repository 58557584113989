import React from "react";
export default function PowerIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 32V35.1458C3 35.7275 3.4725 36.2 4.0542 36.2H6.1479C6.42713 36.1994 6.69473 36.0881 6.89197 35.8905C7.08922 35.6928 7.2 35.425 7.2 35.1458V32C7.2 29.9861 8.8821 27.2267 11.4 26.1641V21.7436C6.5364 22.8692 3 27.737 3 32ZM28.3365 12.4721L23.0235 16.4579C22.2948 17.0039 21.4086 17.3 20.4972 17.3H15.6105C14.445 17.3 13.5 18.245 13.5 19.4105V27.7916C13.5 28.955 14.445 29.9 15.6105 29.9H20.4972C21.4086 29.9 22.2948 30.1961 23.0235 30.7421L28.3386 34.7279C29.6119 35.6834 31.1609 36.2 32.7528 36.2H36.6V11H32.7528C31.161 11 29.6112 11.5166 28.3365 12.4721ZM38.7 15.2V19.4H45V15.2H38.7ZM38.7 32H45V27.8H38.7V32Z"
        fill="#006798"
      />
    </svg>
  );
}
