import React, { useState, useEffect, useContext, useRef } from "react";

//Context
import { TimeContext } from "../../config/time";

import Grid from "@mui/material/Grid";

import ReactEcharts from "echarts-for-react";

//Jquery
import apiURL from "../../config/environment";
import $ from "jquery";
import { CircularProgress, Typography } from "@mui/material";

export default function KPIChart(props) {
  const [chartSettings, setChartSettings] = useState({});
  //const [chart, setChart] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const timeConfig = useContext(TimeContext).timeConfig;

  const container = useRef(null);

  const { xAxisTitle } = props;
  const { endpoint } = props;

  //const container = document.getElementById("chart");

  /*  useEffect(() => {
    if (container !== null && chart !== null) {
      new ResizeObserver(() => chart.resize()).observe(container.current);
    }
  }, [container, chart]); */

  useEffect(() => {
    let isSubscribed = true;

    //if (chart !== null) chart.dispose();

    const token = JSON.parse(localStorage.getItem("userInfo"))?.token;
    setLoading(true);

    $.ajax({
      method: "GET",
      url: apiURL + endpoint + timeConfig.value,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .done((res) => {
        //if (endpoint.includes("travel")) console.log(res);
        const thisYear = new Date(Date.now()).getFullYear();
        let values = [];
        const categories = [...new Set(res.map((item) => item.category))];
        //let colors = [];
        const colors = ["#A244BB", "#3D99CF", "#E58200"];
        //else colors = ["#ED7D31", "#008ACC", "#404470"];

        let months = [];

        if (timeConfig.value === "lyear") {
          months = [...new Set(res.map((item) => item.xAxis))];
        } else {
          months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
        }

        categories.forEach((cat) => {
          const data = [];

          months.forEach((month) => {
            data.push(
              res.find((x) => x.xAxis.trim() === month && x.category === cat)
                ?.yAxis,
            );
          });

          const obj = {
            name: cat,
            type: "line",
            smooth: false,
            symbolSize: cat === thisYear ? 7 : 6,
            symbol: cat === thisYear.toString() ? "circle" : "emptyCircle",
            lineStyle: {
              borderWidth: 2,
              type: cat === (thisYear - 2).toString() ? "dotted" : "solid",
            },
            itemStyle: {
              //borderWidth: 2,
            },
            data: data,
          };

          values.push(obj);
        });

        if (isSubscribed) {
          setChartSettings({
            //color: ["#1e76ac", "#ED7D31", "#404470"],
            color: colors,

            // Global text styles
            textStyle: {
              fontFamily: "Roboto, Arial, Verdana, sans-serif",
              fontSize: 13,
            },

            // Cha23rt animation duration
            animationDuration: 750,

            // Setup grid
            grid: {
              left: 10,
              right: 10,
              top: 50,
              bottom: 10,
              containLabel: true,
            },

            // Add legend
            legend: {
              data: categories,
              itemHeight: 5,
              itemGap: 5,
              top: "top",
            },

            // Add tooltip
            tooltip: {
              trigger: "axis",
              padding: [10, 15],
              textStyle: {
                fontSize: 13,
                fontFamily: "Roboto, sans-serif",
              },
            },

            // Horizontal axis
            xAxis: [
              {
                type: "category",
                boundaryGap: true,
                axisLabel: {
                  color: "#333",
                },
                axisLine: {
                  lineStyle: {
                    color: "#999",
                  },
                }, //Fechas
                data: months,
              },
            ],

            // Vertical axis
            yAxis: [
              {
                type: "value",
                axisLabel: {
                  formatter: "{value} ",
                  color: "#333",
                },
                axisLine: {
                  lineStyle: {
                    color: "#999",
                  },
                },
                splitLine: {
                  lineStyle: {
                    color: ["#eee"],
                  },
                },
                splitArea: {
                  show: true,
                  areaStyle: {
                    color: ["rgba(250,250,250,0.1)", "rgba(0,0,0,0.01)"],
                  },
                },
              },
            ],

            // Add series
            series: values,
          });
          setLoading(false);
        }
      })
      .fail(() => {
        setLoading(false);
        setError(true);
      });
    return () => (isSubscribed = false);
  }, [timeConfig, xAxisTitle, endpoint]);

  if (loading) {
    return (
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        height={"324px"}
      >
        <CircularProgress />
      </Grid>
    );
  } else {
    return (
      <Grid item xs={12} ref={container}>
        {error ? (
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            height={"324px"}
          >
            <Typography color={"error"}>{"Error fetching data"}</Typography>
          </Grid>
        ) : (
          <ReactEcharts
            style={{ height: "324px" }}
            /*  ref={(c) => {
              setChart(c);
            }} */
            option={chartSettings}
          />
        )}
      </Grid>
    );
  }
}
