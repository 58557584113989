import React, { useEffect, useState } from "react";

//Context

//Context
import { useNavigate, useParams } from "react-router-dom";

//Material UI
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

//Material Icons
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import ReplayIcon from "@mui/icons-material/Replay";
import PrintIcon from "@mui/icons-material/Print";

//PowerBI
import { Report } from "powerbi-report-component";

import $ from "jquery";
import apiURL from "../../config/environment";

export default function ReportViewer(props) {
  //Context
  const navigate = useNavigate();
  const { reportId } = useParams();

  //Data management
  const [report, setReport] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [credentials, setCredentials] = useState(null);

  const setFullScreen = () => {
    if (report) report.fullscreen();
  };

  const printReport = () => {
    if (report) report.print();
  };

  const refreshReport = () => {
    if (report) report.refresh();
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    setLoading(true);
    $.ajax({
      method: "GET",
      url: apiURL + "powerbi/reports/" + reportId,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .done((res) => {
        setCredentials(res);
        setLoading(false);
      })
      .fail((res) => {
        if (res.status === 401) {
          localStorage.removeItem("userInfo");
          navigate("/login");
        }
      });
  }, [reportId, navigate]);

  return (
    <Box component="main" sx={{ height: "85vh", p: 3 }}>
      <Grid container justifyContent={"flex-end"}>
        <Grid>
          <IconButton aria-label="refresh" onClick={refreshReport}>
            <ReplayIcon />
          </IconButton>
          <IconButton aria-label="full-screen" onClick={setFullScreen}>
            <FullscreenIcon />
          </IconButton>
          <IconButton aria-label="print" onClick={printReport}>
            <PrintIcon />
          </IconButton>
        </Grid>
      </Grid>

      {isLoading ? (
        <Grid
          sx={{ height: "100%" }}
          justifyContent="center"
          alignItems="center"
          container
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container sx={{ height: "100%", pb: 4 }}>
          <Report
            embedType="report"
            tokenType="Embed"
            accessToken={credentials.embedConfig.embedToken.token}
            embedUrl={credentials.embedConfig.embedUrl}
            embedId={credentials.embedConfig.id}
            extraSettings={{
              filterPaneEnabled: false,
              navContentPaneEnabled: false,
            }}
            permissions="All"
            style={{
              height: "100%",
              width: "100%",
              borderWidth: "0",
            }}
            onLoad={(rep) => {
              console.log("Report Loaded!");
              setReport(rep);
            }}
          />
        </Grid>
      )}
    </Box>
  );
}
