import React from "react";
export default function TwoCarsIconForPanel() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 22L19 13H37L40 22M37 32C36.2044 32 35.4413 31.6839 34.8787 31.1213C34.3161 30.5587 34 29.7956 34 29C34 28.2044 34.3161 27.4413 34.8787 26.8787C35.4413 26.3161 36.2044 26 37 26C37.7956 26 38.5587 26.3161 39.1213 26.8787C39.6839 27.4413 40 28.2044 40 29C40 29.7956 39.6839 30.5587 39.1213 31.1213C38.5587 31.6839 37.7956 32 37 32ZM19 32C18.2044 32 17.4413 31.6839 16.8787 31.1213C16.3161 30.5587 16 29.7956 16 29C16 28.2044 16.3161 27.4413 16.8787 26.8787C17.4413 26.3161 18.2044 26 19 26C19.7956 26 20.5587 26.3161 21.1213 26.8787C21.6839 27.4413 22 28.2044 22 29C22 29.7956 21.6839 30.5587 21.1213 31.1213C20.5587 31.6839 19.7956 32 19 32ZM39.84 12C39.42 10.8 38.28 10 37 10H19C17.72 10 16.58 10.8 16.16 12L12 24V40C12 40.5304 12.2107 41.0391 12.5858 41.4142C12.9609 41.7893 13.4696 42 14 42H16C16.5304 42 17.0391 41.7893 17.4142 41.4142C17.7893 41.0391 18 40.5304 18 40V38H38V40C38 40.5304 38.2107 41.0391 38.5858 41.4142C38.9609 41.7893 39.4696 42 40 42H42C42.5304 42 43.0391 41.7893 43.4142 41.4142C43.7893 41.0391 44 40.5304 44 40V24L39.84 12ZM29.84 6C29.42 4.8 28.28 4 27 4H9C7.72 4 6.58 4.8 6.16 6L2 18V34C2 34.5304 2.21071 35.0391 2.58579 35.4142C2.96086 35.7893 3.46957 36 4 36H6C6.53043 36 7.03914 35.7893 7.41421 35.4142C7.78929 35.0391 8 34.5304 8 34V25.82C7.63083 25.6876 7.29138 25.4837 7.00107 25.22C6.71076 24.9563 6.47528 24.6379 6.3081 24.2831C6.14092 23.9284 6.04533 23.5441 6.02678 23.1523C6.00823 22.7606 6.06709 22.369 6.2 22C6.64 20.8 7.74 20 9 20H9.14L10.54 16H6L9 7H30.18L29.84 6V6Z"
        fill="#006798"
      />
    </svg>
  );
}
