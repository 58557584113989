import React, { useContext } from "react";

//Context
import { TimeContext, timeConfigs } from "../../config/time";

//MUI
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";

export default function SelectPeriod() {
  const setTimeConfig = useContext(TimeContext).setTimeConfig;
  const timeConfig = useContext(TimeContext).timeConfig;
  //const prevTime = localStorage.getItem("timeConfig")??"cyear";

  const handleChange = (event) => {
    const target = event.target;
    let value = target.value;

    localStorage.setItem("timeConfig", value);
    switch (value) {
      case "cyear":
        setTimeConfig(timeConfigs.currentYear);
        break;

      case "lyear":
        setTimeConfig(timeConfigs.lastYear);
        break;

      default:
        setTimeConfig(timeConfigs.currentYear);
        break;
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel variant="standard">
        {"Select time interval to compare"}
      </InputLabel>
      <Select
        native
        name="groupId"
        value={timeConfig.value}
        onChange={handleChange}
        input={<Input />}
      >
        {[
          { id: "cyear", name: "Current Year" },
          { id: "lyear", name: "Last 12 Months" },
        ].map((element, key) => {
          return (
            <option key={key} value={element.id}>
              {element.name}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
}
