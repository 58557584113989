import React, { useState, useEffect } from "react";

//MUI
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import BottomNavigation from "@mui/material/BottomNavigation";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

//Icons
import PedalBikeIcon from "@mui/icons-material/PedalBike";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusAlertIcon from "@mui/icons-material/BusAlert";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import SpeedIcon from "@mui/icons-material/Speed";

//React router dom
import { useNavigate } from "react-router-dom";

import $ from "jquery";
import apiURL from "../../config/environment";
import SelectPeriod from "../input/selectPeriod";

export default function BottomNavbar(props) {
  const [value, setValue] = useState(0);
  const [reports, setReports] = useState([]);
  const navigate = useNavigate();

  const { isAuthenticated } = props;

  useEffect(() => {
    if (isAuthenticated) {
      const token = JSON.parse(localStorage.getItem("userInfo")).token;
      $.ajax({
        method: "GET",
        url: apiURL + "powerbi/reports",
        contentType: "application/json",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .done((res) => {
          setValue(window.location.pathname);
          setReports(res);
        })
        .fail((res) => {
          if (res.status === 401) {
            localStorage.removeItem("userInfo");
            navigate("/login");
          }
        });
    }
  }, [navigate, isAuthenticated]);

  return (
    <Paper elevation={3}>
      <Grid container columnSpacing={2} alignItems="center">
        <Grid item xs={12} sm={9}>
          <BottomNavigation
            sx={{ height: 65 }}
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              navigate(newValue);
            }}
          >
            <BottomNavigationAction
              value={"/"}
              label="Dashboard"
              icon={<DashboardIcon />}
            />

            {reports.map((report) => (
              <BottomNavigationAction
                key={report.id}
                label={report.reportName}
                value={`/Analysis/${report.id}`}
                icon={
                  report.reportName.includes("Incident") ? (
                    <BusAlertIcon />
                  ) : report.reportName.includes("Bicycle") ? (
                    <PedalBikeIcon />
                  ) : (
                    <SpeedIcon />
                  )
                }
              />
            ))}

            <BottomNavigationAction
              value={"/Curbside"}
              label="Curbside"
              icon={<AddRoadIcon />}
            />
          </BottomNavigation>
        </Grid>
        <Grid
          item
          sx={{ display: { xs: "none", sm: "inherit" }, padding: "0 16px" }}
          sm={3}
        >
          <SelectPeriod />
        </Grid>
      </Grid>
    </Paper>
  );
}
