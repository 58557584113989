import React from "react";
export default function SignalIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 20H34V17.72C37.44 16.82 40 13.72 40 10H34V8C34 6.9 33.1 6 32 6H16C14.9 6 14 6.9 14 8V10H8C8 13.72 10.56 16.82 14 17.72V20H8C8 23.72 10.56 26.82 14 27.72V30H8C8 33.72 10.56 36.82 14 37.72V40C14 41.1 14.9 42 16 42H32C33.1 42 34 41.1 34 40V37.72C37.44 36.82 40 33.72 40 30H34V27.72C37.44 26.82 40 23.72 40 20ZM24 38C21.78 38 20 36.2 20 34C20 31.8 21.78 30 24 30C26.2 30 28 31.8 28 34C28 36.2 26.22 38 24 38ZM24 28C21.78 28 20 26.2 20 24C20 21.8 21.78 20 24 20C26.2 20 28 21.8 28 24C28 26.2 26.22 28 24 28ZM24 18C21.78 18 20 16.2 20 14C20 11.78 21.78 10 24 10C26.2 10 28 11.78 28 14C28 16.2 26.22 18 24 18Z"
        fill="#006798"
      />
    </svg>
  );
}
