import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

//Jquery
import apiURL from "../../config/environment";
import $ from "jquery";

export default function LastYearKPIValues(props) {
  const { showActualNumber } = props;
  /* const { tooltipSubTitle } = props;
  const { usePercentage } = props; */
  const { defaultValues } = props;
  const { includeUnits } = props;
  const { endpoint } = props;
  const { type } = props;

  const [info, setInfo] = useState(defaultValues);

  //Color variation
  const [periodVariation, setPeriodVariation] = useState("primary");
  const [periodArrow, setPeriodArrow] = useState(
    <ArrowDownwardIcon color="primary" />,
  );
  const numberAbreviation = (number) => {
    if (number > 1000000) {
      return `${(number / 1000000).toFixed(2)}M`;
    } else if (number > 1000) {
      return `${(number / 1000).toFixed(0)}K`;
    } else {
      return number.toString();
    }
  };

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    let isSubscribed = true;
    //setLoading(false);
    const token = JSON.parse(localStorage.getItem("userInfo"))?.token;

    $.ajax({
      method: "GET",
      url: apiURL + endpoint,
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + token,
      },
    }).done((res) => {
      if (isSubscribed) {
        setInfo({
          ...defaultValues,
          ...res,
          previousPeriodVariation:
            res.previousPeriodVariation !== 0
              ? `${Math.abs(res.previousPeriodVariation.toFixed(0))}%`
              : "—",
        });

        const invertColors = endpoint.includes("incidents");

        if (res.previousPeriodVariation > 0) {
          setPeriodVariation(invertColors ? "error" : "green");
          setPeriodArrow(
            invertColors ? (
              <ArrowUpwardIcon color="error" />
            ) : (
              <ArrowUpwardIcon sx={{ color: "green" }} />
            ),
          );
        } else if (res.previousPeriodVariation < 0) {
          setPeriodVariation(invertColors ? "green" : "error");
          setPeriodArrow(
            invertColors ? (
              <ArrowDownwardIcon sx={{ color: "green" }} />
            ) : (
              <ArrowDownwardIcon color="error" />
            ),
          );
        } else {
          setPeriodVariation("primary");
          setPeriodArrow(null);
        }
      }
    });

    return () => (isSubscribed = false);
  }, [endpoint, defaultValues]);

  return (
    <Grid item sm={12} container spacing={1}>
      <Grid item xs={12} sm={5} container spacing={1} alignItems={"center"}>
        <Grid item xs={6} container direction="column" alignItems="center">
          <Tooltip
            title={
              <React.Fragment>
                <Typography variant="body2" textAlign={"center"}>
                  {`${numberWithCommas(info.actualPeriodValue)} ${type}`}
                </Typography>
                <Typography variant="body2">
                  {info.actualPeriodInterval}
                </Typography>
              </React.Fragment>
            }
          >
            <Grid container alignItems={"baseline"} justifyContent="center">
              <Typography
                color="primary"
                sx={{ fontWeight: 800, fontSize: 39 }}
              >
                {showActualNumber
                  ? numberWithCommas(info.actualPeriodValue)
                  : numberAbreviation(info.actualPeriodValue.toFixed(0))}
              </Typography>
              {includeUnits ? (
                <Typography color="primary" className="condensed" variant="h5">
                  {type}
                </Typography>
              ) : null}
            </Grid>
          </Tooltip>
          <Typography color="textSecondary" fontSize={12} textAlign="center">
            {info.actualPeriodInterval}
          </Typography>
        </Grid>
        <Grid item xs={6} container direction="column" alignItems="center">
          <Tooltip
            title={
              <React.Fragment>
                <Typography
                  variant="body2"
                  textAlign={"center"}
                >{`${numberWithCommas(
                  info.previousPeriodValue,
                )} ${type}`}</Typography>

                <Typography variant="body2">
                  {info.previousPeriodInterval}
                </Typography>
              </React.Fragment>
            }
          >
            <Grid
              item
              sm={12}
              container
              alignItems="center"
              justifyContent="center"
            >
              {periodArrow}
              <Typography
                sx={{ fontWeight: 500, fontSize: 29 }}
                color={periodVariation}
              >
                {info.previousPeriodVariation}
              </Typography>
            </Grid>
          </Tooltip>
          <Typography color="textSecondary" fontSize={12} textAlign="center">
            {info.previousPeriodDescription}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={0} sm={2} container justifyContent="center">
        <Divider orientation="vertical" />
      </Grid>

      <Grid item xs={12} sm={5} container spacing={1} alignItems={"center"}>
        <Grid item xs={4} container direction="column" alignItems="center">
          <Typography sx={{ fontWeight: 500, fontSize: 29 }} color="primary">
            {numberAbreviation(info.monthlyAverage)}
          </Typography>
          <Typography color="textSecondary" fontSize={12} textAlign="center">
            {info.monthlyAverageDescription}
          </Typography>
        </Grid>
        <Grid item xs={4} container direction="column" alignItems="center">
          <Typography sx={{ fontWeight: 500, fontSize: 29 }} color="primary">
            {numberAbreviation(info.weeklyAverage)}
          </Typography>
          <Typography color="textSecondary" fontSize={12} textAlign="center">
            {info.weeklyAverageDescription}
          </Typography>
        </Grid>
        <Grid item xs={4} container direction="column" alignItems="center">
          <Typography sx={{ fontWeight: 500, fontSize: 29 }} color="primary">
            {numberAbreviation(info.dailyAverage)}
          </Typography>
          <Typography color="textSecondary" fontSize={12} textAlign="center">
            {info.dailyAverageDescription}
          </Typography>
        </Grid>
        <Grid item xs={12} justifyContent="center" container>
          <Typography
            sx={{ fontWeight: 600, fontSize: 14 }}
            color="textSecondary"
          >
            {info.secondaryValuesDescription}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
